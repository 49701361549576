import React, { useRef, useState, useEffect } from "react"
import Swiper from "swiper"
import Layout from "../components/layout"
import { GetParameterByName } from "../helpers"
import Join from "../components/join"
import SEO from "../components/seo"

export default props => {
  const location = props.location
  const slug = props.pageContext.slug
  const modelName = props.pageContext.model_name
  const options = props.pageContext.options
  const siteid = props.pageContext.siteid
  const blog = props.pageContext.blog
  const [currentNats, setCurrentNats] = useState(props.pageContext.def_nats)
  const [isLoading, setIsLoading] = useState(true)
  const [isJoin, setIsJoin] = useState(false)
  const imgArr = []
  for (let i = 1; i <= 6; i++) {
    imgArr.push(`/${slug}-slide-${i}.jpg`)
  }
  const theSlider = useRef(null)
  const featured = useRef(null)

  const handleClick = () => {
    setIsJoin(j => !j)
  }

  useEffect(() => {
    if (isJoin) {
      document.body.classList.add("show_form")
    } else {
      document.body.classList.remove("show_form")
    }

    return () => document.body.classList.remove("show_form")
  }, [isJoin])
  useEffect(() => {
    const changeOutNats = () => {
      let natsLink = GetParameterByName("nats")
      if (!!natsLink) {
        localStorage.setItem("nats_af", natsLink)
        setCurrentNats(natsLink)
        return
      }
      if (!!localStorage.getItem("nats_af")) {
        setCurrentNats(localStorage.getItem("nats_af"))
        return
      }
      return
    }
    const FeaturedSwiper = () => {
      if (featured.current) {
        theSlider.current = new Swiper(featured.current, {
          //slidesPerView: "auto",
          slidesPerView: 6,
          spaceBetween: 15,
          on: {
            init: function() {
              setIsLoading(!isLoading)
            },
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            768: {
              slidesPerView: 3,
            },
            640: {
              slidesPerView: 1,
            },
          },
          // virtual: {
          //   slides: data,
          //   renderExternal(data) {
          //     setVirtualData(data)
          //   },
          // },
        })
      }
    }
    // const imgArr = []
    // for (let i = 1; i <= 6; i++) {
    //   imgArr.push(`${slug}-slide-${i}.jpg`)
    // }
    // console.log("giving it this", featured.current)
    FeaturedSwiper()
    changeOutNats()
  }, [])

  return (
    <Layout location={location}>
      <SEO title={modelName} can={`${slug}`} />
      <div className="main">
        <div
          className={isLoading ? "swiper-container noshow" : "swiper-container"}
          ref={featured}
        >
          <div className="swiper-wrapper">
            {imgArr.map((image, i) => {
              return (
                <div
                  key={`slideM${i}`}
                  className="swiper-slide"
                  onClick={handleClick}
                >
                  <img src={image} alt={`${modelName} pic #${i}`} />
                </div>
              )
            })}
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
        <div className="intro_inform">
          <div className="head_text">presents...</div>
          <div className="head_name">
            <strong>{modelName.split(" ")[0]}</strong>{" "}
            {modelName.split(" ")[1] ? modelName.split(" ")[1] : ""}{" "}
            {modelName.split(" ")[2] ? modelName.split(" ")[2] : ""}
          </div>
          <div
            className="head_desc"
            dangerouslySetInnerHTML={{ __html: blog }}
          />
          <div className="bottom" onClick={handleClick}>
            <span className="button_section">
              <span>
                <b>join now</b>
                <small>Access my video library + direct messaging</small>
              </span>
            </span>
          </div>
        </div>
        {/* <div className="intro_list">
          <div className="holder">
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>
                <strong>100% Uncensored</strong> Snaps
              </span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>Solo w/Toys</span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>Public</span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>Personal Videos</span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>Multiple updates a week</span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>Chat with me personally</span>
            </div>
            <div className="item">
              <i className="icon-circle-plus"></i>
              <span>BTS</span>
            </div>
          </div>
        </div> */}
        <div className="bottom_more">
          <div>
            Add <strong>Beauty</strong>, Add <strong>Fun</strong>, Add{" "}
            <strong>Friends</strong> to your life today.
          </div>
          <small>
            Exclusive access to the friends you've always wanted and new ones
            you didn't know about.
          </small>
        </div>
      </div>
      <Join
        handleClick={handleClick}
        slug={slug}
        siteid={siteid}
        modelName={modelName}
        def_nats={currentNats}
        options={options}
        theSlideInfo={`0.0`}
      />
    </Layout>
  )
}

// export const query = graphql`
//   query($slug: String!) {
//     allMysqlSites(filter: { site_name: { eq: $slug } }) {
//       edges {
//         node {
//           free_snapchat
//           site_name
//           site_url
//           mysqlId
//         }
//       }
//     }
//   }
// `
